.grid-content {
  width: 100%;
  position: relative;
  z-index: 1;
  padding: 80px 100px;
  text-align: center;

  @extend %clearfix;

  @media all and (max-width: 1199px) {
    padding: 80px 50px;
  }

  @media all and (max-width: 899px){
    padding: 80px 20px;
  }

  @media all and (max-width: 767px){
    padding: 80px 20px;
  }

  .title-section {
    text-align: center;
    margin-bottom: 40px;

    @media all and (max-width: 767px){
      margin-bottom: 40px;
    }

    .title3 {
      margin-bottom: 60px;
    }

    .main-text {
      width: 700px;
      max-width: 100%;
      margin: auto;
    }
  }

  .site-btn {
    border-color: $colorAccent4;
    background-color: transparent;
    color: $colorAccent4;

    &:hover {
      background-color: $colorAccent4;
      color: $white;
    }
  }

  .grid {
    margin-bottom: 60px;

    @media all and (max-width: 767px) {
      margin-bottom: 20px;
    }

    &:after {
      content: '';
      display: block;
      clear: both;
    }

    .grid-sizer,
    .grid-item {
      width: calc(100% / 3);

      @media all and (max-width: 900px){
        width: 50%;
      }

      @media all and (max-width: 600px){
        width: 100%;
      }
    }

    .grid-item {
      padding: 0 5px;
      float: left;
      margin-bottom: 10px;

      .content {
        background-color: $light;
        padding: 40px;

        .title2 {
          color: $dark;
          font-size: 20px;
        }

        .title3 {
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0.15em;
        }

        .impact {
          font-size: 30px;
          margin-bottom: 20px;
          display: block;

          &.icon-vape {
            color: #FF9F05;
          }

          &.icon-composantes {
            color: #00AEDE;
          }

          &.icon-impact-portefeuille {
            color: #8A00E9;
          }

          &.icon-impact-vie {
            color: #EF005D;
          }

          &.icon-impact-monde {
            color: #22DBB1;
          }
        }

        .main-text {
          font-size: 14px;
        }
      }

      .cta {
        .site-btn {
          width: 50%;
          float: left;
          padding: 15px 10px;
          font-size: 12px;

          &.vrai {
            background-color: $colorAccent2;
            border-color: $colorAccent2;
            color: $white;
          }

          &.faux {
            background-color: $colorAccent3;
            border-color: $colorAccent3;
            color: $white;
          }

          &.blue {
            background-color: $colorAccent1;
            border-color: $colorAccent1;
            color: $white;
          }

          &.purple {
            background-color: $colorAccent4;
            border-color: $colorAccent4;
            color: $white;
          }
        }
      }

      .cta.full {
        .site-btn {
          width: 100%;
        }
      }

      .cta.four {
        .site-btn {
          width: calc(100% / 4);
        }
      }
    }
  }

  .grid .with-image {

    .image {
      position: relative;
      overflow: hidden;

      img {
        display: block;
        transform: scale(1.01);
        transition: all 0.3s ease-in-out;
        opacity: 1;
        overflow: hidden;
      }

      .plus {
        position: absolute;
        right: 15px;
        bottom: 15px;
        background-color: $colorAccent2;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        transition: all 0.3s ease-in-out;
        z-index: 2;

        p {
          font-size: 20px;
          font-weight: 600;
          line-height: 40px;
          color: $white;
          margin: 0;
        }

        i {
          font-size: 20px;
          line-height: 40px;
          color: $white;
          margin-left: 3px;
        }
      }

      &:hover {

        /*img {
          transform: scale(1.1);
        }*/

        .plus {
          background-color: $colorAccent1;
        }
      }
    }
  }

  .grid .with-video {

    .video {
      position: relative;
      overflow: hidden;

      img {
        display: block;
        transform: scale(1.01);
        transition: all 0.5s ease-in-out;
        opacity: 1;
        overflow: hidden;

        &:hover {
          transform: scale(1.1);
          opacity: 0.9;
        }
      }

      .play {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 4px solid $white;
        z-index: 2;

        i {
          color: $white;
          font-size: 28px;
          line-height: 53px;
          margin-left: 4px;
          display: inline-block;
        }
      }
    }
  }

  .grid .grid-item {

    .content-only {

      .content {
        background-color: $light;
        padding: 80px 40px;
        border-bottom: 2px solid rgba(255, 255, 255, .15);
      }
    }

    &:nth-child(odd) {
      .content-only .content {
        background-color: $colorAccent4;

        .impact {
          color: $white;
        }

        .title3 {
          color: $white;
        }
      }
    }
  }

  .post-filters {
    display: flex;
    /* flex-direction: column; */
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 60px;

    @media all and (max-width: 500px) {
      padding: 0;
    }

    a {
      max-width: 220px;
      margin: 0 15px;
      font-weight: 500;
      letter-spacing: 0.05em;
      font-size: 13px;
      display: inline-block;
      line-height: 18px;

      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      span {
        display: block;
        min-height: 50px;
      }

      .filter-icon {
        display: block;
        font-size: 28px;
        margin: 0 auto 10px;
        width: 50px;
        height: 50px;
        background-color: transparent;
        border: 2px solid transparent;
        border-radius: 50%;
        line-height: 50px;

        &.icon-dot-circle-o {
          color: $colorAccent4;
          line-height: 47px;

          &:before {
            margin-left: 1px;
          }
        }

        &.icon-vape {
          color: #FF9F05;
        }

        &.icon-composantes {
          color: #00AEDE;
          line-height: 48px;

          &:before {
            margin-left: 2px;
          }
        }

        &.icon-impact-portefeuille {
          color: #8A00E9;
          line-height: 47px;

          &:before {
            margin-left: 2px;
          }
        }

        &.icon-impact-vie {
          color: #EF005D;
        }

        &.icon-impact-monde {
          color: #22DBB1;
        }
      }

      &:hover,
      &.active {

        .filter-icon {
          transition: all 0.3s ease-in-out;
        }

        .icon-dot-circle-o {
          border-color: $colorAccent4;
        }

        .icon-vape {
          border-color: #FF9F05;
        }

        .icon-composantes {
          border-color: #00AEDE;
        }

        .icon-impact-portefeuille {
          border-color: #8A00E9;
        }

        .icon-impact-vie {
          border-color: #EF005D;
        }

        .icon-impact-monde {
          border-color: #22DBB1;
        }
      }
    }
  }
}

  /*.js-loader{
    margin-top: 70px;
    max-width: 60px;
    display: none;
  }*/


/* With Effect: Move Up */
.grid.with-effect > div{
  opacity: 0;
  &.shown,
  &.paged-item{
    opacity: 1;
  }
}
.grid.with-effect > div.animate {
  -webkit-transform: translateY(200px);
  transform: translateY(200px);
  -webkit-animation: moveUp 0.65s ease forwards;
  animation: moveUp 0.65s ease forwards;
}

@-webkit-keyframes moveUp {
  0% { }
  100% { -webkit-transform: translateY(0); opacity: 1; }
}

@keyframes moveUp {
  0% { }
  100% { -webkit-transform: translateY(0); transform: translateY(0); opacity: 1; }
}
