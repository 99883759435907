@font-face {
  font-family: 'icomoon';
  src:  url('/assets/css/fonts/icomoon.eot?swhe75');
  src:  url('/assets/css/fonts/icomoon.eot?swhe75#iefix') format('embedded-opentype'),
    url('/assets/css/fonts/icomoon.ttf?swhe75') format('truetype'),
    url('/assets/css/fonts/icomoon.woff?swhe75') format('woff'),
    url('/assets/css/fonts/icomoon.svg?swhe75#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change /assets/css/fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-composantes:before {
  content: "\e900";
}
.icon-vape:before {
  content: "\e901";
}
.icon-checkmark:before {
  content: "\e902";
}
.icon-impact-monde:before {
  content: "\e903";
}
.icon-impact-vie:before {
  content: "\e904";
}
.icon-impact-portefeuille:before {
  content: "\e905";
}
.icon-impact-corps:before {
  content: "\e906";
}
.icon-impact-cigarette:before {
  content: "\e907";
}
.icon-play:before {
  content: "\e908";
}
.icon-arrow-left:before {
  content: "\e909";
}
.icon-arrow-right:before {
  content: "\e90a";
}
.icon-phone:before {
  content: "\e90b";
}
.icon-share2:before {
  content: "\e90c";
}
.icon-mail:before {
  content: "\e90d";
}
.icon-location:before {
  content: "\e90e";
}
.icon-quotes-left2:before {
  content: "\e90f";
}
.icon-quotes-left:before {
  content: "\e910";
}
.icon-check:before {
  content: "\e911";
}
.icon-angle-down:before {
  content: "\e912";
}
.icon-angle-up:before {
  content: "\e913";
}
.icon-angle-right:before {
  content: "\e914";
}
.icon-angle-left:before {
  content: "\e915";
}
.icon-check3:before {
  content: "\e916";
}
.icon-external-link:before {
  content: "\e917";
}
.icon-pinterest:before {
  content: "\e918";
}
.icon-google-plus:before {
  content: "\e919";
}
.icon-instagram:before {
  content: "\e91a";
}
.icon-linkedin:before {
  content: "\e91b";
}
.icon-youtube:before {
  content: "\e91c";
}
.icon-twitter:before {
  content: "\e91d";
}
.icon-facebook:before {
  content: "\e91e";
}
.icon-dot-circle-o:before {
  content: "\e91f";
}
.icon-check2:before {
  content: "\e942";
}
.icon-phone2:before {
  content: "\e943";
}
