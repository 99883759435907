
.info001 {
  position: relative;
  margin: 20px 100px;
  background-color: $light;

  @extend %clearfix;

  @media all and (max-width: 1199px) {
    margin: 20px 50px;
  }

  @media all and (max-width: 899px) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 20px;
  }

  .content {
    width: 50%;

    @media (max-width: 991px) {
      width: 100%;
    }

    .wrap {
      margin: 0 auto;
      padding: 60px;
      text-align: center;

      @media (max-width: 1299px) {
        padding: 40px;
      }

      @media (max-width: 767px) {
        padding: 40px 25px;
      }

      .title3 {
        padding-bottom: 0px;
        margin-bottom: 30px;
      }

      .main-text {
        margin-bottom: 30px;

        @media (min-width: 992px) and (max-width: 1299px) {
          font-size: 14px;
          line-height: 20px;
        }

        a {
          text-decoration: underline;
        }
      }

      .main-list li {

        @media (min-width: 992px) and (max-width: 1299px) {
          font-size: 14px;
          line-height: 20px;
        }
      }

      .like-facebook {
        background-color: $colorAccent3;
        color: $white;
        letter-spacing: 0.05em;
        border: 2px solid $colorAccent3;
        display: block;
        width: 250px;
        max-width: 100%;
        padding: 15px;
        margin: auto;
        font-weight: 500;
        position: relative;

        .fb-like {
          display: none;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-left: -42px;
          margin-top: -10px;
          transition: all .5s;
        }

        &:hover {
          background-color: transparent;
          border-color: transparent;
          cursor: default;

          .text {
            opacity: 0;
          }

          .fb-like {
            display: block;

            span {
              transform: translateY(0);
            }
          }
        }
      }

      /*.site-btn {

        .fb-like {
          display: none;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-left: -55px;
          margin-top: -10px;
          transition: all .5s;
        }

        &:hover {
          background-color: $white;

          .fb-like {
            display: block;

            span {
              ransform: translateY(0);
            }
          }
        }
      }*/
    }
  }

  .carousel {
    width: 50%;

    @media (max-width: 991px) {
      width: 100%;
    }

    .carousel-cell {
      width: 100%;
      height: auto;

      iframe {
        width: 100%;
        height: 500px;
        display: block;
        border: 0;
      }
    }

    .flickity-prev-next-button {
      background: transparent;
      z-index: 1;

      &:before {
        content: '\e90a';
        font-family: 'icomoon' !important;
        width: 44px;
        height: 44px;
        background-color: $white;
        position: absolute;
        left: 0;
        top: 0;
        color: $colorAccent2;
        font-weight: bold;
        font-size: 16px;
        border-radius: 50%;
        line-height: 44px;
      }

      &.previous {
        &:before {
          content: '\e909';
        }
      }

      svg {
        display: none;
      }
    }
  }
}
