.publication-wrap {

  .hero {
    position: relative;
    height: 80vh;
    background-size: cover;
    background-attachment: fixed;
    background-position: bottom;

    &.hero-capsule {
      background-color: $dark;
      height: 65vh;
    }

    [data-bg-cover-img]{
      display: none;
    }

    .overlay-opacity {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #000;
      opacity: 0.6;
    }

    .content {
      margin-top: 40px;
      text-align: center;
      position: relative;
      z-index: 2;

      @media all and (max-width: 767px) {
        padding: 0 25px;
      }

      .title2 {
        color: $white;
        margin-bottom: 40px;
        line-height: 40px;
      }

      .title4 {
        color: $white;
        margin-bottom: 20px;
      }

      .site-hr {
        background: $colorAccent2;
      }
    }
  }

  .publication-data {
    background-color: $light;
    padding-bottom: 80px;

    @media all and (max-width: 767px) {
      padding: 0;
    }

    .video-wrap {
      width: 100%;
      max-width: 800px;
      z-index: 100;
      margin: -16vh auto 0px;

      @media all and (max-width: 767px) {
        position: relative;
        top: 0;
        left: 0;
        transform: translate(0, 0);
      }

      .video {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    .content {
      position: relative;
      width: 1000px;
      max-width: 100%;
      padding: 25px 45px;
      margin: -15vh auto 0px;
      background: $white;
      letter-spacing: 0.05em;
      font-size: 14px;

      @media all and (max-width: 767px) {
        padding: 45px 20px;
        margin-top: 0;
        background-color: $light;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 18px;
        margin: 20px 0 5px;
        line-height: 20px;
        font-family: $fontTitle;
        text-transform: uppercase;
        letter-spacing: 0.1em;
      }

      p {
        margin-bottom: 15px;
        letter-spacing: 0.05em;
        font-size: 14px;

        strong {
          display: block;
          margin-bottom: 5px;
        }

        a {
          color: $colorAccent2;
        }
      }

      ul,
      ol {
        padding: 0 0 0 25px;

        li {
          line-height: 30px;
          font-size: 14px;
          position: relative;
          list-style-type: none;

          &:before {
            content: "\e917";
            font-family: icomoon!important;
            speak: none;
            font-style: normal;
            font-weight: 400;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            position: absolute;
            top: 7px;
            left: -25px;
            color: $colorAccent2;
            width: 20px;
            height: 20px;
            font-size: 16px;
          }
        }
      }
    }
  }
}





/*.publication-wrap
{
  width: 100%;

  .hero-animation{
    .title1{
      color:$colorAccent1;
      font-weight:500;
    }
  }

  .details {

    .description-data p{
      color:$colorAccent1;
      font-size:15px;
    }


    .produit-sub-header
      {
      background-color: #E8E9EB;
      padding: 15px 22px;
      color: #fff;

      h2{
        width: 100%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 500;
        font-family: $fontTitle;
        margin:0px;
      }

      @media all and (max-width: 767px){
        background-color: #E8E9EB;
        h2
        {
          color: #fff;
        }
      }
    }
  }

  .capsule-header
  {
    width: 100%;

    text-align: center;

    position: relative;

    @media all and (max-width: 1024px){
      h1.title3{
        font-size:32px;
        padding-top:80px;
        padding-bottom:80px;
      }
    }

    @media all and (max-width: 767px){
      min-height: 0;

      h1.title3{
        padding:50px 8% 50px 8%;
        font-size:20px;

      }
    }

    .title-background
    {
      width: 100%;
      padding-bottom: 150px;
      background-color: $colorAccent1;
    }

    .title-wrap {
      padding-top:80px;
      padding-bottom:80px;

      .title2 {
        color: $white;
        font-weight: 500;
        font-size: 18px;
        letter-spacing: 0.1em;
      }

      .site-hr {
        margin-bottom: 15px;
        background: $colorAccent2;
        height: 2px;
      }

      .title3{
        font-size: 33px;
        margin:0 auto;
        max-width:740px;
        line-height:1.5;
        letter-spacing: 0.2em;
        font-family:$fontTitle;
        color: $white;
        font-weight: 600;
        padding: 0;
      }

    }

    .video-wrap
    {
      width: 100%;
      max-width: 1280px;
      z-index: 100;
      margin: 0 auto;
      margin-top: -160px;
      @media all and (max-width: 767px){
        position: relative;
        top: 0;
        left: 0;
        transform: translate(0, 0);
      }
      .video
      {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        /*height: 0;

        iframe
        {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    .overlay-opacity
    {
      width: 100%;
      min-height: calc( 85vh - 85px );
      background-color: #000;
      opacity: 0.4;
      @media all and (max-width: 767px){
        min-height: 0;
      }
    }

  }

  .publication-data.capsule-data{
    margin-bottom: 0;
    padding: 0 25px;
    .title3{
      font-size:24px;
      text-align: center;
      padding:0px;
      margin:0px;
    }
  }

  .hero-recette{
    position:relative;
    .site-btn{
      padding:16px 20px;
      position:absolute;
      margin:30px;
      top:0;
      left:0;
    }

    .tool-bar
    {
      display: inline-block;
      list-style-type: none;
      position: relative;
      top: 150px;

      border-radius: 3px;
      border: 2px solid $colorAccent2;

      margin: 0;
      padding: 0;

      @media all and (max-width: 900px){
        top: 30px;
        max-width: 300px;
      }

      @extend %clearfix;

      li
      {
        display: inline-block;
        float: left;
        min-width: 225px;
        text-align: center;

        border-right: 2px solid $colorAccent2;

        padding: 5px 15px;

        @media all and (max-width: 900px){
          width: 100%;
          border-right:0;
          border-bottom: 2px solid $colorAccent2;
          &:last-child
          {
            border-bottom: 0;
          }
        }

        &:last-child
        {
          border-right: 0;
        }


        @extend %clearfix;

        img
        {
          display: inline-block;
          width: auto;
          height: 30px;
          position: relative;
          top: 10px;
          &.portions-icon
          {
            height: 23px;
            top: 6px;
          }
        }

        p
        {
          display: inline-block;
          color: $colorAccent1;
          font-family: $fontTitle;
          text-transform: uppercase;
          font-size: 13px;
          font-weight: 500;
          letter-spacing: 1px;
          margin-left: 10px;
        }
      }
    }

  }

  .publication-header
  {
    width: 100%;
    min-height: calc( 85vh - 85px );

    background: $light center top no-repeat;
    background-size: cover;

    text-align: center;

    position: relative;

    [data-bg-cover-img]{
      display: none;
    }

    @media all and (max-width: 767px){
      min-height: calc( 45vh - 85px );
    }

    .overlay-opacity
    {
      width: 100%;
      min-height: calc( 85vh - 85px );
      background-color: #000;
      opacity: 0.4;
      @media all and (max-width: 767px){
        min-height: calc( 45vh - 85px );
      }
    }

    .auteur
    {
      position: absolute;
      bottom: 20%;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 100;

      img
      {
        width: 100px;
        border-radius: 50px;
        margin-bottom: 12px;
        @media all and (max-width: 767px){
          width: 60px;
          border-radius: 30px;
        }
      }

      .name
      {
        color: $colorAccent1;
        padding: 0;
        margin: 0;
        text-transform: uppercase;
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 1;
        font-weight: 500;
      }

      .date
      {
        color: #838486;
        padding: 0;
        margin: 0;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 0.5px;
        @media all and (max-width: 767px){
          line-height: 1.5;
        }
      }
    }

    .content
    {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);

      width: 100%;
      height: auto;

      padding-left: 20px;
      padding-right: 20px;

      z-index: 2;

      text-align: center;

      .title1
      {
        font-size: 42px;
        margin: 0 auto;
        max-width: 900px;
        color: #fff;
        font-weight: 600;
        letter-spacing: 0.2em;
        line-height: 1.3;
        font-family: $fontTitle;

        @media all and (max-width: 767px){
          font-size: 24px;
          line-height: 1.5;
          padding: 0;
          margin-bottom: 20px;
          max-width: 540px;
        }

        span
        {
          color: $colorAccent1;
        }

      }

    }

  }

  .publication-data
  {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    text-align: justify;
    padding: 0 25px 50px 25px;

    color: $grey;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 20px;

    &:first-of-type{
      padding-top: 75px;
    }

    @media all and (max-width: 767px){
      padding: 25px 15px;
      font-size: 16px;
      line-height: 28px;

      &:first-of-type{
        padding-top: 35px;
      }
    }

    .video-wrap {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      /*padding-top: 25px;
      height: 0;
    }
    .video-wrap iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .lettrine {
          /*font-family: $fontHero;*/
          /*font-family: $fontTitle;
          color: $colorAccent2;
          font-size: 100px;
          float: left;
          margin-right: 8px;
          margin-bottom: 7px;
          margin-top: 32px;
        }


    @extend %clearfix;

    .article-title{
      background-color: $light;
      color: $colorAccent1;
      margin: 0;
      padding: 15px 20px;
      font-weight: 400;
      text-transform: uppercase;
    }

    p,
    p span,
    .main-text,
    ul li
    {
      font-family: $fontText;
      color: $grey;
      font-size: 15px;
      line-height: 30px;
      margin-bottom: 20px;
      letter-spacing: 0.05em;

      @media all and (max-width: 767px){
        font-size: 16px;
        line-height: 28px;
      }
    }

    ul li{
      margin: 0;
      padding: 0;
    }

    a
    {
      color: $colorAccent2;
    }

    .sub-title,
    h1,
    h2,
    h3,
    h4
    {
      color: $colorAccent1;
      font-size: 20px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      font-family: $fontTitle;
      margin-bottom: 10px;
      text-align: left;
    }

    img
    {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      margin: 20px 0;
    }



    .ingredients
    {
      width: 40%;
      display: inline-block;
      float: left;
      @media all and (max-width: 767px){
        width: 100%;
      }
      .recette-sub-header{
        h2{
          color: $white;
          margin: 0;
        }
      }
      ul
      {
        background-color: $light;
        padding: 25px 25px 15px 45px;
        margin: 0;
        li
        {
          line-height: 1.4;
          margin-bottom: 25px;
          font-size: 16px;
          font-weight: 400;
          list-style-type: none;
          position: relative;
          text-align: left;
        }
        li:before
        {
          content: '';
          display: inline-block;
          width: 13px;
          height: 13px;
          background-image: url('../images/petit-crochet-bleu.png');
          background-size: 13px;
          position: absolute;
          top: 5px;
          left: -25px;
        }
      }
    }


    .preparation
    {
      width: 60%;
      display: inline-block;
      float: left;
      padding-left: 5px;
      @media all and (max-width: 767px){
        width: 100%;
        padding-left: 0;
        margin-top: 15px;
      }
      .recette-sub-header
      {
        background-color: $light;
        h2
        {
          color: $colorAccent1;
          margin: 0;
        }
        @media all and (max-width: 767px){
          background-color: $colorAccent1;
          h2
          {
            color: $colorAccent1;
          }
        }
      }
      ul
      {
        list-style-type: decimal;
        padding: 25px 25px 15px 35px;
        margin: 0;
        li
        {
          line-height: 1.4;
          margin-bottom: 25px;
          font-size: 16px;
          font-weight: 400;
        }
      }
    }

    .recette-sub-header
    {
      background-color: $colorAccent1;
      padding: 15px 20px;
      color: $colorAccent1;
      h2
      {
        width: 100%;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }

  }

  @media all and (max-width:790px){
    .hero-recette{
      .site-btn.back-button{
        display:none;
      }
    }
  }

}

.cta-section-wrap
{
  padding: 0px 5vw;
  margin-bottom: 50px;
  .cta-section {
    background-color: $colorAccent1;
    text-align: center;
    padding: 60px 15px;

    @media all and (max-width: 600px){
      padding: 35px 15px;
    }

    .title2 {
      color: #fff;
      font-weight: 600;
      font-size: 28px;
      letter-spacing: 0.2em;
      line-height: 1.4;
      padding: 0;
      margin-bottom: 25px;
      @media all and (max-width: 600px){
        font-size: 22px;
      }
    }

    .title3 {
      font-size: 16px;
      margin: 0 0 5px;
      padding:0;
      color: $white;
      @media all and (max-width: 600px){
        font-size: 14px;
      }
    }

    .btn-4:before {
      line-height: 44px;
    }
  }
}*/