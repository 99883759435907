/**
 * Cropper
 *
 */
.modal-title{
  text-align: center;
}
.site-content section.avatar{
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  background: #fff;
  position: initial;
  top: -6px;
  margin-bottom: -60px;
  padding-bottom: 60px;
  .heading{
    padding-top: 45px;
  }
}
.avatar-view {
  cursor: pointer;
  text-align: center;
  img{
    display: inline-block;
    width: 100%;
    max-width: 500px;
    height: auto;
  }
}

.avatar-body {
  padding-right: 15px;
  padding-left: 15px;
}

.avatar-upload {
  overflow: hidden;
  text-align: center;
  p{
    padding: 3% 0;
  }
  label, input[type=file]{
    display: inline-block;
    margin: 0;
    padding: 0;
  }
}

.avatar-upload label {
  display: none;
  width: 100px;
}

.avatar-upload input {
  display: block;
  margin-left: 110px;
}

.avatar-alert {
  margin-top: 10px;
  margin-bottom: 10px;
}

.avatar-wrapper {
  height: 364px;
  width: 100%;
  margin-top: 15px;
  box-shadow: inset 0 0 5px rgba(0,0,0,.25);
  background-color: #fcfcfc;
  overflow: hidden;
}

.avatar-wrapper img {
  display: block;
  height: auto;
  max-width: 100%;
}

.avatar-preview {
  float: left;
  margin-top: 15px;
  margin-right: 15px;
  border: 1px solid #eee;
  border-radius: 4px;
  background-color: #fff;
  overflow: hidden;
}

.avatar-preview:hover {
  border-color: #ccf;
  box-shadow: 0 0 5px rgba(0,0,0,.15);
}

.avatar-preview img {
  width: 100%;
}

.preview-lg {
  height: 184px;
  width: 184px;
  margin-top: 15px;
}

.preview-md {
  height: 100px;
  width: 100px;
}

.preview-sm {
  height: 50px;
  width: 50px;
}

@media (min-width: 992px) {
  .avatar-preview {
    float: none;
  }
}

.avatar-btns {
  margin-top: 30px;
  margin-bottom: 15px;
}

.avatar-btns .btn-group {
  margin-right: 5px;
}

.loading {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff url("../images/loader.gif") no-repeat center center;
  opacity: .75;
  filter: alpha(opacity=75);
  z-index: 20140628;
}

.table td.drag-handle-col{
  /*padding-left: 8px;
  padding-right: 8px;*/
  text-align: center;
}

.handle{
  display: inline-block;
  cursor: move;
  cursor: -webkit-grabbing;
  font-size: 25px;
  color: #21263a;
}

.note-editor .note-editing-area .note-editable{
  width: 100%;
  padding: 10px 10px;
  border: none;
  background-color: #eee;
  border-radius: 0;
  font-weight: 400;
  outline: none;
  font-size: 14px;
  letter-spacing: 1px;
  color: #565656;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 5px 0 -5px 0;
}

[data-field-duplicate]{
  display: none;
}

.select2js-recettes{
  width: 100%;
}