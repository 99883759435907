.init-grid {
  position: relative;

  .row {
    position: relative;
    @extend %clearfix;

    &.max-width {
      max-width: 1400px;
      margin: 0 auto;
    }
  }

  .col {
    position: relative;
    width: 100%;

    &.half {
      float: left;
      width: 50%;
    }

    &.one-third {
      float: left;
      width: calc(100% / 3);
    }

    &.two-third {
      float: left;
      width: calc(100% / 3 * 2);
    }

    &.one-fourth {
      float: left;
      width: 25%;
    }

    &.one-fifth {
      float: left;
      width: 20%;
    }
  }

  @media all and (max-width: 767px) {

    .col {

      &.half,
      &.one-third,
      &.two-third,
      &.one-fourth,
      &.one-fifth {
        float: none;
        width: 100%;
      }
    }
  }
}