
/*
 *    Colors
 *    ------
 */


$black : #000;
$white : #fff;

$grey : #777777;
$gray : #777777;

$light : #F8F3FD; // Mauve pâle
$dark   : #0E0256; // Mauve foncé
$darker : #0E0256;

$colorAccent1 : #5AB9D8; // Bleu RSEQ
$colorAccent2 : #22DBB1; // Turquoise
$colorAccent3 : #EF005D; // Rose
$colorAccent4 : #3A0464; // Mauve




/*
 *    Fonts
 *    -----
 */

$fontTitle          : "brandon-grotesque",sans-serif;
$fontTitleBold      : "brandon-grotesque",sans-serif;
$fontText           : "droid-serif",serif;




/*
 *    Breakpoints
 *    -----------
 */

