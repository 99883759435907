
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0,0,0,.7);
  z-index: 9995;

  &.non-visible {
    display: none;
  }
}


.popp001 {
  position: fixed;
  top: 50%;
  left: 50%;
  height: auto;
  width: 500px;
  max-width: 100%;
  z-index: 9997;
  background-color: $white;
  transform: translate(-50%, -50%);

  &.non-visible {
    display: none;
  }

  .close-btn {
    position: absolute;
    right: 15px;
    top: 15px;
    background-color: $white;
    border: 2px solid $dark;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 22px;
    font-weight: 900;
    line-height: 24px;
    z-index: 9999;
  }

  .content {
    position: relative;
    padding: 30px 0 40px;
    width: 100%;
    text-align: center;

    i.impact {
      font-size: 30px;
      margin-bottom: 10px;
      display: block;

      &.icon-vape {
        color: #FF9F05;
      }

      &.icon-composantes {
        color: #00AEDE;
      }

      &.icon-impact-portefeuille {
        color: #8A00E9;
      }

      &.icon-impact-vie {
        color: #EF005D;
      }

      &.icon-impact-monde {
        color: #22DBB1;
      }
    }

    .title3 {
      margin-bottom: 30px;
      font-size: 16px;
      padding: 0 50px;
    }

    .reponse {
      padding: 10px 20px;
      color: $white;
      background-color: $colorAccent2;
      margin-bottom: 30px;

      &.faux {
        background-color: $colorAccent3;
      }

      &.both {
        background-color: $colorAccent4;
      }
    }

    .main-text {
      padding: 0 20px;
      margin-bottom: 30px;
    }

    .link {

      i {
        font-size: 10px;
        margin-left: 5px;
      }
    }
  }
}


/*.overlay{

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: rgba(0, 28, 36, .75);

  opacity: 0;

  z-index: -1;

  transition: all 0.25s ease-in-out;

  &.visible{
    opacity: .8;
    z-index: 100;
  }

}*/

/*.popp001 {

  position: fixed;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -70%);

  width: 100%;
  max-width: 750px;
  height: auto;
  padding: 30px;

  background: $white;
  text-align: center;

  opacity: 0;
  z-index: -1;

  transition: all 0.25s ease-in-out;

  &.visible{
    opacity: 1;
    z-index: 200;
    transform: translate(-50%, -50%);
  }

  .title3{
    position: relative;
    width: 100%;
    line-height: 1;
    text-align: center;
    background: $white;
    color: $grey;
    display: block;
    font-size: 24px;
    margin-bottom: 40px;
    padding-bottom: 40px;

    &:after{
      content: '';

      position: absolute;
      left: 50%;
      bottom: 0;
      width: 100px;
      height: 3px;
      margin-left: -50px;
      background-color: $colorAccent2;
    }

  }

  .content.size-chart {
    .main-text {
      font-size: 11px;
      line-height: 16px;
      text-transform: initial;
      letter-spacing: 0.05em;
      font-family: $fontText;

      a {
        color: $colorAccent2;
      }
    }

    .title3 {
      margin-bottom: 10px;
    }
  }

  .title4 {
    font-size: 16px;
    letter-spacing: 0.2em;
    color: $colorAccent2;
    position: relative;
    padding-bottom: 0;
  }

  p {
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: $dark;
    font-family: $fontTitle;
    font-weight: 400;
    font-size: 15px;
    margin-bottom: 10px;
  }

  .col {
    padding: 0 5px;
  }

  img {
    width: 100%;
    max-width: 100%;
    margin-bottom: 5px;
  }

  .popup-close-btn{
    position: absolute;
    top: 0;
    right: 0;

    transform: translate(50%, -50%);

    width: 40px;
    height: 40px;

    background: $colorAccent3;
    border-radius: 50%;

    .close-cross{
      font-size: 22px;
      font-weight: 100;
      font-family: sans-serif;
      text-align: center;
      line-height: 38px;
      text-indent: 1px;
      color: $colorAccent2;
      font-weight: 500;
    }

  }

  .input-promo {
    position: relative;
    width: 80%;
    height: auto;
    margin: 0 auto;
    padding: 5px 0;

    .js-news-success{
      margin-top: 30px;
      display:none;

      a {
        font-family: $fontTitle;
        letter-spacing: 0.05em;
        font-size: 15px;
        padding-bottom: 4px;
        border-bottom: 1px solid $colorAccent2;
        color: $colorAccent2;
        transition: all 0.25s ease-in-out;

        &:hover {
          border-color: $dark;
          color: $dark;
        }
      }
    }

    .form-field{
      position: relative;
      width: 100%;
      max-width: 300px;
      margin: auto;

      button{
        border: none;
      }
    }

    p {
      letter-spacing: 0.05em;
      font-size: 14px;
      color: $dark;
      margin-bottom: 30px;
      text-transform: initial;
      font-family: $fontText;
      line-height: 18px;

      span {
        color: $white;
        font-family: $fontTitle;
        text-transform: uppercase;
        letter-spacing: 0.15em;
        display: block;
        font-size: 14px;
        background-color: $colorAccent1;
        padding: 12px 20px;
        border-radius: 30px;
        width: 175px;
        margin: 10px auto 0;
      }

    }

    ::-webkit-input-placeholder { /* Chrome/Opera/Safar
      color: $dark;
      font-weight: 400;
    }
    ::-moz-placeholder { /* Firefox 19+
      color: $dark;
      font-weight: 400;
    }
    :-ms-input-placeholder { /* IE 10+
      color: $dark;
      font-weight: 400;
    }
    :-moz-placeholder { /* Firefox 18-
      color: $dark;
      font-weight: 400;
    }

    input:focus::-webkit-input-placeholder { color:transparent; }
    input:focus:-moz-placeholder { color:transparent; }
    input:focus::-moz-placeholder { color:transparent; }
    input:focus:-ms-input-placeholder { color:transparent; }


    input[type="email"]{
      width: 300px;
      max-width: 100%;
      padding: 10px 30px 10px 15px;
      border: none;
      border-bottom: 1px solid $dark;
      outline: none;
      border-radius: 0;
      background: transparent;
      color: $dark;
      font-size: 14px;
      letter-spacing: 0.05em;
      text-align: center;
      font-weight: 400;
      transition: background 0.3s ease-in-out, border-color 0.3s ease-in-out;

      &.parsley-error{

        background-color: #fee8ea;
        border-bottom-color: #F44336;
      }
    }

    [type="submit"]{
      position: absolute;
      top: 0;
      right: 0;
      width: 50px;
      height: 100%;
      border: none;
      outline: none;
      border-radius: 0px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      color: $dark;
      background-color: transparent;
      text-transform: uppercase;

      i{
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-15%, -50%);
        width: 100%;
        text-align: right;
        font-weight: bold;
        transition: transform 0.2s ease-in-out;

        &:hover {
          transform: translate(0%, -50%);
        }



        &.submitted{
          transform: translate(25%,-50%);

          &:before{
            display: block;
            content: '';
            height:25px;
            width: 25px;
            background: url('../images/loader.svg') no-repeat center;
            background-size:contain;

          }
        }

      }

    }

    .parsley-errors-list{
      display: none;
    }

  }

  @media (min-width: 768px) and (max-width: 1024px) {
    width: 650px;
    max-width: 95%;

    .content {
      padding: 30px;

      .col.half {
        width: 50%;
        float: left;
      }
    }
  }

  @media (max-width: 767px) {

    .content {
      padding: 20px 15px;
    }

    .title3 {
      font-size: 18px;
      text-align: center;
      padding: 20px 0px;
      margin-bottom: 30px;
      line-height: 24px;

      &:after {
        width: 50px;
        margin-left: -25px;
      }

    }

    p {
      font-size: 12px;
    }

    h2{
      font-size: 15px;
    }

    .main-form label{
      font-size: 12px;
    }

    .main-form input,
    .main-form select{
      height: 40px;
      margin-bottom: 0;
    }

    .main-form{
      padding: 0;
    }

    .main-form input[type="submit"].site-btn,
    .main-form button.site-btn{
      margin-top: 10px;
      line-height: 0;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .main-form .parsley-errors-list {
      right: 3px;
      bottom: 1px;
      font-size: 10px;
    }

    .custom-popup .title3{
      font-size: 19px;
    }
  }

  @media only screen and (max-width: 574px) {

    .popup-close-btn{
      top: 15px;
      right: 15px;
      transform: translate(0, 0);
    }

  }

  @media only screen and (max-width: 464px) {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    border-radius: 0;

    transform: none;

    overflow: scroll;

    &.visible{
      transform: none;
    }

    h2{
      font-size: 15px;
    }

    .main-form label{
      font-size: 12px;
    }

    .main-form input,
    .main-form select{
      height: 40px;
      margin-bottom: 0;
    }

    .main-form{
      padding: 0;
    }

    .main-form input[type="submit"].site-btn,
    .main-form button.site-btn{
      width: 100%;
      max-width: inherit;
      margin-top: 10px;
      line-height: 0;
    }

    .main-form .parsley-errors-list {
      right: 3px;
      bottom: 1px;
      font-size: 10px;
    }

    .custom-popup .title3{
      font-size: 19px;
    }

  }

  @media (max-height: 899px) and (max-width: 574px) {
    .popup-close-btn{
      top: 7px;
    }
  }

}*/
