.info013 {
  width: 100%;
  padding: 80px 50px;
  text-align: center;

  @media all and (max-width: 767px) {
    padding: 40px 25px;
  }

  .title3 {
    margin-bottom: 20px;
  }

  .main-text {
    margin: 0 auto 20px;
    width: 800px;
    max-width: 100%;

    a {
      text-decoration: underline;
      transition: all 0.2s ease-in-out;

      &:hover {
        color: $colorAccent3;
      }
    }
  }

  .list-wrap {
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    width: 800px;
    max-width: 100%;
    margin: 0 auto;

    .list-element {
      width: 25%;
      margin-top: 30px;

      @media all and (max-width: 1024px) {
        width: 25%;
      }

      @media all and (max-width: 767px) {
        width: 50%;
      }

      img {
        width: 160px;
        margin: auto;
      }
    }
  }
}