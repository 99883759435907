.main-form{

  .form-row,
  .form-row-custom{
    padding: 10px 0;
  }

  label{
    color: $dark;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;

    .fa{
      position: relative;
      top: 2px;
      font-size: 145%;
      cursor: pointer;
      color: #16a085;
    }

    &[for="pubdate"]{
      margin: 0;
    }

  }

  .form-row{
    input,
    select,
    textarea {
      width: 100%;
      height: 40px;
      padding: 5px 10px;
      border: none;
      background-color: rgba(0, 0, 0, .05);
      border-radius: 0;
      font-weight: 400;
      outline: none;
      font-size: 14px;
      letter-spacing: 1px;
      color: #565656;
      margin: 5px 0 15px 0;

      &.list{
        margin-bottom: -5px;
        border-bottom: 1px solid rgba( 0, 0, 0, .10 );
      }
    }
  }

  //.form-row{
  //  textarea{
  //    min-height: 1000px;
  //  }
  //}

  .form-field.file{
    input{
      background: none;
    }
  }

  .form-row{
    input[disabled],
    select[disabled],
    textarea[disabled]{
      padding-left: 25px;
      padding-right: 25px;
      border: none;
      border-radius: 0;
      background: rgba(0, 0, 0, .02);
      color: #333;
    }
  }

  .form-radio{
    float: left;
    width: 20%;
    text-align: center;
    border: 2px solid #eee;
    border-radius: 6px;
    margin: 0 25px 25px 0;

    input,
    label{
      position: relative;
      display: inline-block;
      width: auto;
      margin: 0 5px;
      padding: 0;
      vertical-align: top;
      height: 40px;
      line-height: 40px;
      color: #132434;
      cursor: pointer;
      text-transform: none;
    }
  }

}

.form-field{
  &.fr,
  &.en{
    position: relative;
    width: 50%;
    float: left;
  }
  &.fr{
    padding-right: 20px;
  }
  &.en{
    padding-left: 20px;
  }
  .adder{
    float: right;
    width: 26px;
    height: 26px;
    padding: 0;
    border-radius: 50%;
    background-color: #16a085;
    border: 2px solid #16a085;
    color: #e7eaf3;
    font-size: .8em;
    font-weight: 300;
    text-decoration: none;
    text-align: center;
    line-height: 23px;
    margin: 10px 0;
  }
  .field{
    position: relative;

    &:hover{
      .remover{
        display: block;
      }
    }

    .remover{
      display: none;

      position: absolute;
      top: 50%;
      right: 15px;
      transform: translate( 0, -50%);

      width: 26px;
      height: 26px;
      padding: 0;
      border-radius: 50%;
      background-color: #e41f39;
      border: 2px solid #e41f39;
      color: #e7eaf3;
      font-size: .8em;
      font-weight: 300;
      text-decoration: none;
      text-align: center;
      line-height: 23px;


    }
  }
}

.main-form .form-field.membre{
  margin-bottom: 2%;
  border-radius: 6px;
  border: 2px solid #eee;
  padding: 3% 5%;

  &.half{
    width: 48%;
  }

}