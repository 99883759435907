/**
 *  CLEARFIX AN ELEMENT
 *  ==============================================
 *
 * Usage :
 *
 * .container-with-floated-children {
 *     @extend %clearfix;
 * }
 *
 */
%clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}