.info006 {
  position: relative;
  margin: 0 100px;

  @extend %clearfix;

  @media all and (max-width: 1199px) {
    margin: 0 50px;
  }

  @media all and (max-width: 899px) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 20px;
  }

  .content {
    width: 50%;
    min-height: 400px;
    text-align: center;
    background-color: $colorAccent4;

    @media all and (max-width: 899px) {
      width: 100%;
    }

    .wrap {
      width: 700px;
      max-width: 100%;
      margin: 0 auto;
      padding: 60px;

      @media all and (max-width: 767px) {
        padding: 40px 25px;
      }

      .pre-cta {
        color: $white;
        margin-bottom: 5px;
        font-family: $fontTitle;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.1em;
        font-size: 15px;
      }

      .title2 {
        margin-bottom: 30px;
        color: $white;
      }

      .main-text {
        margin-bottom: 30px;
        color: $white;
      }
    }
  }

  .visual {
    width: 50%;
    background-image: url('../images/concours-rseq-degueu.jpg');
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;

    @media all and (max-width: 899px) {
      width: 100%;
      background-image: none;
    }

    img {
      display: none;

      @media all and (max-width: 899px) {
        display: block;
      }
    }
  }
}

.post-list .info006 {
  margin: 0;
}