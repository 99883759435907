.hero005 {
  position: relative;
  height: auto;
  // background-image: url('../images/hero.jpg');
  // background-size: contain;
  // background-position: bottom;
  // margin-top: 85px;

  // @media all and (max-width: 767px) {
  //   background-image: url('../images/hero-mobile.jpg');
  // }

  .hero-img {
    width: 100%;
    margin-top: 85px;
  }

  .content {
    margin-top: 40px;
    padding: 50px 100px;
    text-align: left;

    @media all and (max-width: 1199px) {
      padding: 50px 50px;
    }

    @media all and (max-width: 899px) {
      padding: 40px 20px;
    }

    .title1 {
      color: $white;
      margin-bottom: 30px;
    }

    .pre-cta {
      color: $colorAccent2;
      display: inline-block;
      font-size: 20px;
      margin-left: 5px;
      margin-top: 10px;
      padding-bottom: 5px;
      //border-bottom: 1px solid transparent;
      transition: all 0.2s ease-in-out;

      i.icon-arrow-left {
        padding-left: 2px;
        padding-right: 5px;
      }

      &:hover {
        //border-color: $colorAccent2;

        i.icon-arrow-right {
          padding-left: 15px;
        }

        i.icon-arrow-left {
          margin-left: -5px;
        }
      }
    }

    .link-wrap {
      margin-top: 60px;
    }
  }
}

.post-list .hero005,
.terms .hero005 {
  min-height: 30vh;
  max-height: 400px;
  margin-top: 85px;
  background: url('/assets/images/hero-vrai-faux.jpg') center center no-repeat;background-size: cover;

  .content {
    margin-top: 0;

    .title1 {
      margin-bottom: 0;
      font-size: 70px;
      font-weight: 900;
      letter-spacing: 0;
      line-height: 1;
    }
  }
}
