.foot001 {
  padding: 25px 100px;
  background: $white;
  border-top: 2px solid $colorAccent4;
  @extend %clearfix;

  @media all and (max-width: 1199px) {
    padding: 25px 50px;
  }

  @media all and (max-width: 899px) {
    padding: 15px 20px;
  }

  @media all and (max-width: 767px) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .main-text{
    font-size: 12px;

    @media all and (max-width: 767px) {
      font-size: 12px;
      text-align: center;
    }

    a {
      border-right: 1px solid $white;
      padding-right: 8px;
      margin-right: 8px;

      &:last-of-type {
        border: 0;
        padding: 0;
        margin: 0;
      }
    }
  }

  .content-left, .content-right {
    width: 50%;

    @media all and (max-width: 767px) {
      width: 100%;
      margin: 2px 0;
    }
  }

  .content-right {
    text-align: right;
  }
}