
.foot003 {
  position: relative;

  .bloc-text {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100px;
    width: 420px;
    background: $colorAccent4;
    z-index: 2;

    @media all and (max-width: 1199px) {
      left: 50px;
    }

    @media all and (max-width: 899px) {
      left: 20px;
    }

    .content {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      width: 100%;
      height: auto;
      padding: 60px 50px 45px;
      text-align: center;

      .title3 {
        color: $white;
        margin-bottom: 30px;
      }

      .main-text {
        color: $white;
        margin-bottom: 15px;
      }

      a {
        transition: all 0.2s ease-in-out;

        &:hover {
          color: $colorAccent2;
        }
      }

      img {
        width: 100px;
        margin: 5px auto 0;
      }
    }
  }

  .map{
    position: relative;
    width: 100%;
    height: 400px;
    min-width: 100%;
    max-width: 100%;
    max-height: 515px;

    z-index: 1;
  }

  @media only screen and (max-width: 1024px) {
    .bloc-text{
      .content{
        padding: 60px 50px;
      }
    }
  }

  @media only screen and (max-width: 799px) {
    .bloc-text,
    .bloc-text .content{
      position: relative;
      float: none;
      top: auto;
      left: auto;
      bottom: auto;
      right: auto;
      transform: none;
      width: 100%;

      text-align: center;

      p, .title4{
        text-align: center;
      }

      p:last-child{
        margin-bottom: 0;
        padding-bottom: 0;

        text-align: center;
      }


    }
  }

}