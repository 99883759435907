.info012 {
	position: relative;
	overflow: hidden;
	background-color: $light;
	margin: -110px 100px 20px;

	@media all and (max-width: 1199px) {
    margin: -110px 50px 20px;
  }

	@media all and (max-width: 899px) {
		margin: -50px 20px 20px;
	}

	.col {
		height: 300px;
		border: 1px solid $white;

		@media all and (max-width: 1024px) {

			&.one-fourth {
				width: 50%;
				float: left;
			}
		}

		@media all and (max-width: 499px) {

			&.one-fourth {
				width: 100%;
			}
		}
	}

	.content {
		text-align: center;
		width: 100%;
		padding: 20px 40px;

		img {
			height: 55px;
			width: auto;
			display: block;
			margin: 0 auto 30px;
		}

		.title3 {
			font-size: 16px;
			font-weight: 400;
			letter-spacing: 0.1em;
			margin-bottom: 10px;
			font-weight: 600;
		}

		.main-text {
			font-size: 13px;
			line-height: 16px;
			letter-spacing: 0;
		}
	}
}