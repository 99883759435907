.title1,
.title2,
.title3,
.title4 {
  font-family: $fontTitle;
  text-transform: uppercase;
  color: $dark;
  margin: 0;
  padding: 0;
  letter-spacing: 0.05em;
  font-weight: 600;

  i {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    margin-top: -15px;
    font-size: 255%;
    color: $grey;
  }

  strong {
    font-family: $fontTitle;
    font-weight: 900;
  }

  @media all and (max-width: 969px) {
    i {
      margin-top: 0;
    }
  }
}

.title1 {
  font-size: 100px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 100px;

  @media all and (max-width: 1099px) {
    font-size: 72px;
    line-height: 80px;
  }

  @media all and (max-width: 599px) {
    font-size: 52px;
    line-height: 60px;
  }

  @media all and (max-width: 499px) {
    font-size: 40px;
    line-height: 42px;
  }

}

.title2 {
  font-size: 28px;
  line-height: 36px;
  font-weight: 900;
  letter-spacing: 0;

  @media all and (max-width: 1099px) {
    font-size: 24px;
    line-height: 30px;
  }

  @media all and (max-width: 599px) {
    font-size: 20px;
    line-height: 28px;
  }

  @media all and (max-width: 499px) {
    font-size: 18px;
    line-height: 24px;
  }

}

.title3 {
  font-size: 24px;

  @media all and (max-width: 1099px) {
    font-size: 20px;
  }

  @media all and (max-width: 599px) {
    font-size: 18px;
  }

  @media all and (max-width: 499px) {
    font-size: 16px;
  }

}

.title4 {
  font-size: 22px;
  text-transform: initial;
  font-weight: 400;

  @media all and (max-width: 1099px) {
    font-size: 20px;
  }

  @media all and (max-width: 599px) {
    font-size: 18px;
  }

  @media all and (max-width: 499px) {
    font-size: 16px;
  }
}

.main-text {
  color: $dark;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: 24px;
  font-family: $fontText;

  @media (max-width: 599px) {
    font-size: 14px;
  }
}

.pre-cta {
  color: $dark;
  margin: 0 0 10px;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  font-family: $fontText;

  i {
    font-size: 12px;
    padding-left: 10px;
    transition: all 0.2s ease-in-out;
  }
}

.main-list {
  width: 100%;
  color: $dark;
  margin: 0 0 25px;
  padding: 0;

  list-style-type: none;

  li {
    display: inline-block;
    position: relative;
    width: 100%;
    margin: 0;
    margin-bottom: 5px;
    padding-left: 30px;
    font-size: 15px;
    letter-spacing: 0.05em;

    i {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      color: $colorAccent2;
      font-size: 135%;
    }

  }

}

a {
  cursor: pointer;
}

.site-hr-container {
  @extend %clearfix;
}

.site-hr {
  display: inline-block;
  width: 55px;
  height: 3px;
  background: $grey;
  margin: 0 auto 25px auto;
  padding: 0;
  border: none;
}