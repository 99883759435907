.site-btn-center{
  text-align: center;
}

.site-btn{
  display: inline-block;
  margin: 0;
  padding: 15px 30px;
  border: 2px solid $colorAccent3;
  background: $colorAccent3;
  text-align: center;
  color: $white;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  font-family: $fontTitle;
  letter-spacing: 0.1em;

  transition: all 0.2s ease-in-out;

  &:hover{
    background: transparent;
    border-color: $colorAccent3;
    color: $colorAccent3;
  }

  &.grey{
    background: $grey;
    border-color: $grey;

     &:hover{
      background: $white;
      border-color: $white;
      color: $colorAccent1;
    }
  }

  &.white{
    background-color: $white;
    border-color: $white;
    color: $colorAccent1;

    &:hover{
      background: transparent;
      color: $white;
    }
  }

  &.outlined{
    background: transparent;
    border-color: $colorAccent1;
    color: $colorAccent1;

    &:hover{
      background: $colorAccent1;
      border-color: $colorAccent1;
      color: $white;
    }
  }

  &.contrast{

    &:hover{
      border-color: $colorAccent1;
    }
  }

  @media all and (max-width: 767px) {
    padding: 13px 20px;
    font-size: 14px;
  }

  @media all and (max-width: 599px) {
    padding: 13px 15px;
    font-size: 13px;
  }

}