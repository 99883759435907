
.info016 {
  position: relative;
  margin: 0 100px;

  @media all and (max-width: 1199px) {
    margin: 0 50px;
  }

  @media all and (max-width: 991px) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  @media all and (max-width: 899px) {
    margin: 0 20px;
  }

  .content {
    width: 50%;
    background-color: $colorAccent4;

    @media all and (max-width: 991px) {
      width: 100%;
    }

    .wrap {
      width: 650px;
      max-width: 100%;
      margin: 0 auto;
      padding: 60px;

      @media all and (max-width: 1299px) {
        padding: 40px;
      }

      @media all and (max-width: 767px) {
        padding: 40px 25px;
      }

      .title2 {
        color: $white;
      }

      .main-text {
        color: $white;
        font-size: 13px;
        margin-top: 15px;
        margin-bottom: 20px;

        a {
          text-decoration: underline;
        }
      }

      .main-list li {
        color: $white;
        font-size: 13px;
        line-height: 16px;

        i {
          color: $colorAccent3;
          font-size: 16px;
          font-weight: bold;
        }

        a {
          text-decoration: underline;
        }
      }
    }
  }

  .visual {
    width: 50%;
    background-image: url('../images/besoin-daide.jpg');
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;

    @media all and (max-width: 991px) {
      width: 100%;
      background-image: none;
    }

    img {
      display: none;

      @media all and (max-width: 991px) {
        display: block;
      }
    }
  }
}

.terms .info016 {
  margin: 0;
}