.term001 {

  .policy-section {
    background-color: $white;
    padding: 80px 100px;

    @media all and (max-width: 1199px) {
      padding: 80px 50px;
    }

    @media all and (max-width: 899px) {
      padding: 40px 20px;
    }

    .content {
      position: relative;

      .title3 {
        font-size: 18px;
        margin: 20px 0;
        line-height: 20px;
      }

      p.main-text {
        margin-bottom: 15px;
        line-height: 18px;
        font-size: 14px;

        strong {
          display: block;
          margin-bottom: 5px;
        }

        a {
          color: $colorAccent2;
        }
      }

      ul {
        padding: 0;

        li {
          line-height: 18px;
          font-size: 14px;

          &:before {
            content: "\e90f";
            font-family: icomoon!important;
            speak: none;
            font-style: normal;
            font-weight: 400;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            position: absolute;
            top: 0;
            left: 0;
            color: $colorAccent2;
            width: 20px;
            height: 20px;
            font-size: 20px;
          }
        }
      }
    }
  }
}





