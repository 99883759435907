
.info011 {
  position: relative;
  padding: 60px 50px 140px;
  background-color: $dark;
  text-align: center;

  @media all and (max-width: 899px) {
    padding: 40px 25px 60px;
  }

  .title3 {
    margin-bottom: 30px;
    color: $white;
  }

  .main-text {
    margin: 0 auto 30px;
    width: 800px;
    max-width: 100%;
    color: $white;
  }

  .pre-cta {
    color: $white;
  }

}