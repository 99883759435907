.main-form{
  padding: 25px 0 0 0;

  .form-row{
    @extend %clearfix;
  }

  .form-field,
  .form-input{
    position: relative;
    width: 100%;

    &.half{
      float: left;
      width: 49%;
      margin-right: 2%;

    }

    &.one-third{
      float: left;
      width: 32%;
      margin-right: 2%;
    }

    &.one-forth{
      float: left;
      width: 24%;
      margin-right: 2%;

      @media only screen and (max-width: 365px) {
        width: 63%;
        margin-right: 2%;

        &:last-child{
          width: 35%;
          margin-right: 0;
        }

     }

    }

    &.three-forth{
      float: left;
      width: 74%;
      margin-right: 2%;

       @media only screen and (max-width: 365px) {
          width: 63%;
          margin-right: 2%;
       }

    }

    &:last-child,
    &.last
    {
      margin-right: 0;
    }

  }



  label{
    display: inline-block;
    width: 100%;
    text-align: left;
    color: $grey;
    font-weight: 400;
    font-size: 16px;

    span{
      display: block;
    }

  }


  input,
  select,
  textarea{
    width: 100%;
    height: 50px;
    padding: 5px 10px;
    margin-bottom: 10px;
    border: 1px solid rgba(0, 0, 0, .10);

    background-color: #ABB0B7;
    border-radius: 3px;

    color: #3a3a3a;
    font-weight: 400;

    outline: none;

  }

  textarea{
    height: 175px;
    min-height: 175px;
    max-width: 100%;
    min-width: 100%;
  }

  input[type="submit"],
  button{

    outline: none;

  }

  input[type="submit"].site-btn,
  button.site-btn{


  }


  .payment-errors{
    color: #e74c3c;
  }

  input.parsley-success,
  select.parsley-success,
  textarea.parsley-success{
    color: #3a3a3a;
  }

  input.parsley-error,
  select.parsley-error,
  textarea.parsley-error{
    color: #DA620C;
  }

  .checkbox{
    line-height: 25px;
    vertical-align: top;

    label span,
    input{
      display: inline-block;
      width: inherit;
      height: 25px;
      margin-right: 0.5%;
      line-height: 25px;
      vertical-align: top;
    }

  }


  .has-error{

    input,
    select{
      background: #e74c3c;
    }

  }

  .parsley-errors-list{
    position: absolute;
    right: 0;
    bottom: -7px;
    font-size: 12px;
    font-weight: 500;
    color: #DA620C;
  }

}