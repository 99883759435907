.flex {
  position: relative;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  .col {

    &.half {
      width: 50%;
    }

    &.one-third {
      width: calc(100% / 3);
    }

    &.one-fifth {
      width: calc(100% / 5);
    }

    @media (max-width: 767px) {

      &.half {
        width: 100%;
      }
      &.one-third {
        width: 100%;
      }
      &.one-fifth {
        width: 100%;
      }
    }
  }

  &.vertical-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  &.vertical-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  &.vertical-end {
    -webkit-box-align: flex-end;
    -ms-flex-align: flex-end;
    align-items: flex-end;
  }

  &.horizontal-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  &.horizontal-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  &.horizontal-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  &.grow {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  &.ungrow {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }

  &.wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  &.column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

}


