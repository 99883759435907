.head004 {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 85px;
  padding: 15px 100px;
  transition: all 0.2s ease-in-out;
  background-color: $colorAccent1;

  @extend %clearfix;

  @media all and (max-width: 1299px) {
    padding: 15px 50px;
  }

  @media all and (max-width: 899px) {
    padding: 15px 20px;
  }

  .logo {
    width: 250px;
    height: auto;

    /*&.miniature {
      display: none;
    }*/
  }

}

.head004 .desktop-navigation {

  @media all and (max-width: 1024px) {
    position: fixed;
    top: 85px;
    right: 0;
    width: 400px;
    max-width: 100%;
    height: calc(100vh - 85px);
    background: $colorAccent4;
    transform: translate( 100%, 0);
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-direction: column;
    flex-direction: column;
    z-index: 1000;
    transition: all 0.35s ease;

    &.active {
      transform: translate(0, 0);
    }
  }

  .link {
    color: $white;
    font-weight: 600;
    font-family: $fontTitle;
    letter-spacing: 0.05em;
    margin: 0 20px;
    font-size: 14px;
    text-transform: uppercase;

    @media all and (max-width: 1199px) {
      font-size: 13px;
      margin: 0 10px;
    }

    @media all and (max-width: 767px) {
      margin: 0px 0px 10px;
    }
  }

  .social {
    display: inline-block;
    width: 36px;
    height: 36px;
    background-color: $white;
    border-radius: 50%;
    text-align: center;
    margin-left: 10px;

    @media all and (max-width: 767px) {
      margin: 20px 5px 0;
    }

    i {
      line-height: 36px;
      color: $colorAccent1;
      font-size: 18px;
    }
  }

  .site-btn {
    color: $white;
    border-color: $colorAccent1;
    margin-left: 20px;

    @media all and (max-width: 599px) {
      display: none;
    }
  }
}

.head004 .mobile-navigation {

  @media all and (min-width: 1024px) {
    display: none;
  }

  .burger {
    position: relative;
    width: 37px;
    height: 50px;
    cursor: pointer;
    transform: scale(.8);
    z-index: 999;

    &:hover {

      .lines {

        &:before{
          top: -12px;
        }

        &:after{
          top: 10px;
        }
      }
    }

    &.active {

      .lines {
        background: transparent;

        &:before {
          top: 0;
          background: $white;
          transform: rotate(-45deg);
        }

        &:after {
          top: -2px;
          background: $white;
          transform: rotate(45deg);
        }
      }
    }

    .lines {
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -1px;
      display: block;
      width: 100%;
      height: 2px;
      background: $white;

      &:before,
      &:after {
        display: block;
        content: '';
        position: relative;
        top: -10px;
        width: 100%;
        height: 100%;
        background: $white;
        transition: all 0.25s ease-out 0.1s;
      }

      &:after {
        top: 8px;
      }
    }
  }
}

.head004.follow {
  background: $dark;
  border-bottom: 1px solid rgba(0,0,0,.2);
  margin-top: 0;
  padding: 10px 50px;
  position: fixed;

  @media all and (max-width: 767px) {
    padding: 10px 25px;
  }

  /*.logo {
    display: none;

    &.miniature {
      display: block;
      width: 100px;
    }
  }*/
}

.head004 .overlay {
  position: fixed;
  top: 85px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0);
  transition: all ease 0.33s;
  display: none;

  @media all and (max-width: 767px) {
    &.active{
      z-index: 998;
      display: block;
      background: rgba(0, 0, 0, .33);
    }
  }
}
