
/**
 * Import Vendor for components dependencies
 *
 */
@import '_themes/css/vendors/parsley';
@import '_themes/css/vendors/sweetalert';
@import '_themes/css/vendors/flickity';

/**
 * Import website Framwork base
 *
 *    Settings
 *    --------
 *      Colors pallet and Fonts
 *
 *    Mixins
 *    --------
 *      Usefull CSS functions to help develop
 *
 */
@import '_themes/css/settings';
@import '_themes/css/mixins';

/**
 * Reset
 *
 */
@import '_themes/css/base/global';
@import '_themes/css/base/normalize';

/**
 * Small Components
 *
 */
@import '_themes/css/grid';
@import '_themes/css/flex-grid';
@import '_themes/css/icon-fonts';
@import '_themes/css/btns';
@import '_themes/css/forms';
@import '_themes/css/typography';

/**
 * Import Component's core modules
 *
 */
@import '_components/head004/assets/css/navigation';
@import '_components/hero001/assets/css/hero';
@import '_components/info001/assets/css/about';
@import '_components/info006/assets/css/contest';
@import '_components/blog001/assets/css/blog-grid-list';
@import '_components/blog001/assets/css/blog-details-post';
@import '_components/info011/assets/css/arguments-bloc-title';
@import '_components/info012/assets/css/arguments';
@import '_components/info016/assets/css/suggestions';
@import '_components/info013/assets/css/ressources';
@import '_components/foot003/assets/css/contact';
@import '_components/foot001/assets/css/footer';


@import '_components/popp001/assets/css/reponse-modal';
@import '_components/term001/assets/css/privacy-policy';



/**
 * Admin
 *
 */
@import '_themes/css/admin/admin';
@import '_themes/css/admin/loader';
@import '_themes/css/admin/datepicker';


